import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

class SliderActus {
    constructor()
    {
        this.slideractus = new Swiper('.actu-slider', {
            modules: [Navigation],
            loop: true,
            centeredSlides: true,
            slideActiveClass: 'actu-slide-active',
            navigation: {
                nextEl: '.actu-slider-next',
                prevEl: '.actu-slider-prev',
            },
            slidesPerView: 1,
            breakpoints: {
                1350: {
                    slidesPerView: 3,
                },
                900: {
                    slidesPerView: 2,
                },
            },
        });
    }
}

export default SliderActus;
