export class Accordion {

constructor(accordeon_selector, content_selector)
{
    let accordeonList = document.querySelectorAll(accordeon_selector);
    accordeonList.forEach((elt) => {
        if (elt.classList.contains('active')) {
            // Set the first always active accordion heigth to allow smooth transition
            let content = elt.querySelector(content_selector);
            content.style.maxHeight = content.scrollHeight + 'px';
        }

        elt.addEventListener('click', (e) => {
            // If the cliocked element is the content,
            // Do not toggle the active class
            if (e.target.closest(content_selector)) {
                return;
            }
            elt.classList.toggle('active');
            let content = elt.querySelector(content_selector);
            if (elt.classList.contains('active')) {
                content.style.maxHeight = content.scrollHeight + 'px';
            } else {
                content.style.maxHeight = 0;
            }
        });
    })
}

}