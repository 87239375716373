import { Header } from '../util/header';
import { Domaines } from './domaines';
import {Accordion} from '../util/accordion';
import AOS from 'aos';

export default {
    init() {
        new Header()
        new Domaines()
        new Accordion('.simple-accordeon_item', '.simple-accordeon_container')
        AOS.init();
    },
    finalize() {
    },
};
