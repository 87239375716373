export class Domaines {
    constructor()
    {
        let domainesEls = document.querySelectorAll('.domaine')
        this.registerSeeMore(domainesEls)
    }

    registerSeeMore(els)
    {
        els.forEach((el) => {
            let button = el.querySelector('.--readmore')
            button.addEventListener('click', (evt) => {

                evt.preventDefault()
                let newState = (el.getAttribute('state') === 'excerpt') ? 'content' : 'excerpt'
                let buttonText = (newState === 'content') ? 'Réduire' : 'En savoir plus'
                let excerpt = el.querySelector('.domaine-text__excerpt')
                let content = el.querySelector('.domaine-text__content')

                el.setAttribute('state', newState)
                excerpt.classList.toggle('hidden')
                content.classList.toggle('hidden')
                evt.target.innerText = buttonText

            })
        })
    }
}
