import gsap from 'gsap'

export class Header {
    constructor()
    {

        this.scrollHandler()

        this.banner = document.querySelector('header.banner')

        this.tl = gsap.timeline({paused:true});
        this.animation()

        const burger = document.querySelector('#burger')
        if (burger) {
            burger.addEventListener('click', evt => {
                evt.preventDefault()
                burger.classList.toggle('active')
                this.banner.classList.toggle('expanded')

                if (this.banner.classList.contains('expanded')) {
                    this.tl.play()
                } else {
                    this.tl.reverse()
                }
            })
        }
    }

    animation()
    {
        const nav = this.banner.querySelector('.nav-primary.mobile')
        const menu_items = nav.querySelectorAll('ul.nav > .menu-item')

        this.tl.to(nav, {x: 0})
        this.tl.to(menu_items, {x: 0, opacity: 1, stagger: 0.15})
    }

    scrollHandler()
    {
        Header.checkforScroll();
        document.addEventListener('scroll', () => {
            Header.checkforScroll()
        })
    }

    static checkforScroll()
    {
        let height = document.documentElement.scrollTop || document.body.scrollTop || window.scrollY;
        if (height > 0) {
            let header = document.querySelector('header.banner')
            if (!header.classList.contains('scrolled')) {
                header.classList.toggle('scrolled')
            }
        } else {
            let header = document.querySelector('header.banner')
            if (header.classList.contains('scrolled')) {
                header.classList.toggle('scrolled')
            }
        }
    }
}
