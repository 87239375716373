import '@css/main.scss';


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import SliderActus from './util/SliderActus';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
    common,
});

jQuery(document).ready(() => {
    routes.loadEvents()
    if (jQuery('.actu-slider').length > 0) {
        new SliderActus();
    }
    // fix safari being dumb on ios
    document.addEventListener('touchstart', function () {}, true);
});
